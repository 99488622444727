import React from 'react'

import PageWithLeftMenu from '../../components/PageWithLeftMenu'
import P from '../../components/P'
import SEO from '../../components/seo'

import items from './_items'
import ExternalLink from '../../ExternalLink'

export default () => {
  return (
    <PageWithLeftMenu title="S.E.B.E" items={items}>
      <SEO
        title="SEBE - sistem cultul penticostal"
        keywords={[`sebe`, 'penticostal', `fundația ebe`]}
      />
      <P style={{ textAlign: 'center' }}>
        <P>Sistemul de Educație Biblică prin Extensie</P>
        <P>Coordonator sistem S.E.B.E.</P>
        <P>
          <i>Nelu Mureșan</i>
        </P>
      </P>

      <P>
        <b>Viziunea S.E.B.E.</b>
      </P>

      <P ml={4}>
        Un sistem dezvoltat de pregătire de slujitori în Bisericile
        Penticostale, prin Centre de Pregătire cu baza și sub autoritatea
        bisericilor locale și a Comunităților Regionale din Cultul Creștin
        Penticostal din România.
      </P>
      <P>
        <b>Avantaje oferite de S.E.B.E.</b>
      </P>
      <P ml={4}>
        <ol>
          <li>
            {' '}
            Adaptabilitatea cursurilor, din punct de vedere metodologic, astfel
            încât au acces la pregătire credincioși de orice vârstă;
          </li>
          <li>
            Posibiliatatea participării la cursuri fără întreruperea activității
            de slujire în biserica locală sau în cariera profesională;
          </li>
          <li>
            Acoperirea nevoilor de echipare în multe domenii de slujire din
            biserică printr-o varietate mare de cursuri oferite;
          </li>
          <li>
            Un număr nelimitat de participanți, comparat cu limitarea necesară
            în pregătirea asigurată la nivel de Seminar sau Institut Teologic;
          </li>
          <li>
            O programă specifică de dezvoltare a lucrării cu femeile în biserica
            locală, pusă la dispoziția celor interesați;
          </li>
          <li>
            Asigurarea întregii bibliografii necesare, prin Fundația EBE, care
            și editează cursurile destinate acestui scop;
          </li>
          <li>
            Rentabilitate din punct de vedere financiar, costurile fiind minime
            (se acoperă doar prețul cursurilor cerute/studiate).
          </li>
        </ol>
        Pentru mai multe informatii accesati site-ul:{' '}
        <ExternalLink to="//www.sebe.ro">www.sebe.ro</ExternalLink>
      </P>
    </PageWithLeftMenu>
  )
}
